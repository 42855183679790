import {
  IconMail,
  IconList,
  IconFrame,
  IconGauge,
  IconPackage,
  IconAnalyze,
  IconSettings,
  IconPackages,
  IconHandStop,
  IconPackageOff,
  IconAddressBook,
  IconListDetails,
  IconPackageExport,
  IconTruckDelivery,
  IconCurrencyDollar,
  IconDeviceDesktopAnalytics,
} from '@tabler/icons-react';

const iconSize = 16;
export const payloadCookieKey = '_p';
export const orderDetailsCacheKey = 'details';

export const requiredField = {
  required: { value: true, message: 'Field is required' },
};

export const routes = [
  { label: 'Orders', url: '/orders', icon: IconPackage },
  { label: 'Jobs', url: '/jobs', icon: IconAnalyze },
  { label: 'Monitoring', url: '/monitoring', icon: IconDeviceDesktopAnalytics },
  { label: 'Cms', url: '/cms', icon: IconSettings },
];

/**
 * Orders icons.
 */
export const allOrdersIcon = <IconList size={iconSize} />;
export const newOrdersIcon = <IconPackage size={iconSize} />;
export const batchOrdersIcon = <IconPackages size={iconSize} />;
export const activeOrdersIcon = <IconListDetails size={iconSize} />;
export const onHoldOrdersIcon = <IconHandStop size={iconSize} />;
export const sentOrdersIcon = <IconTruckDelivery size={iconSize} />;
export const canceledOrdersIcon = <IconPackageOff size={iconSize} />;
export const missingAddressOrdersIcon = <IconAddressBook size={iconSize} />;

/**
 * Cms icons.
 */
const generalSectionIcon = <IconGauge size={iconSize} />;
const materialsSectionIcon = <IconFrame size={iconSize} />;
const emailPopupSectionIcon = <IconMail size={iconSize} />;
const shippingSectionIcon = <IconPackageExport size={iconSize} />;
const upsellsSectionIcon = <IconCurrencyDollar size={iconSize} />;

export const subroutes = {
  '/orders': [
    {
      label: 'All',
      url: '/orders/all',
      icon: allOrdersIcon,
    },
    {
      label: 'Active',
      url: '/orders/active',
      icon: activeOrdersIcon,
    },
    {
      label: 'New',
      url: '/orders/new',
      icon: newOrdersIcon,
    },
    {
      label: 'On Hold',
      url: '/orders/on-hold',
      icon: onHoldOrdersIcon,
    },
    {
      label: 'Missing Address',
      url: '/orders/missing-address',
      icon: missingAddressOrdersIcon,
    },
    {
      label: 'Canceled',
      url: '/orders/canceled',
      icon: canceledOrdersIcon,
    },
    {
      label: 'Sent',
      url: '/orders/sent',
      icon: sentOrdersIcon,
    },
    {
      label: 'Batch',
      url: '/orders/batch',
      icon: batchOrdersIcon,
    },
  ],
  '/cms': [
    {
      label: 'General',
      url: '/cms/general',
      icon: generalSectionIcon,
    },
    {
      label: 'Shipping',
      url: '/cms/shipping',
      icon: shippingSectionIcon,
    },
    {
      label: 'Upsells',
      url: '/cms/upsells',
      icon: upsellsSectionIcon,
    },
    {
      label: 'Materials',
      url: '/cms/materials',
      icon: materialsSectionIcon,
    },
    {
      label: 'Email popup',
      url: '/cms/email-popup',
      icon: emailPopupSectionIcon,
    },
    {
      label: 'Subscriptions',
      url: '/cms/subscriptions',
      icon: emailPopupSectionIcon,
    },
    {
      label: 'Discounts',
      url: '/cms/discounts',
      icon: emailPopupSectionIcon,
    },
  ],
  // '/monitoring': [
  //   { label: 'Admin', url: '/monitoring/admin' },
  //   { label: 'Application', url: '/monitoring/application' },
  //   { label: 'Pdf', url: '/monitoring/pdf' },
  //   { label: 'Upload', url: '/monitoring/upload' },
  // ],
};
