import { lazy } from 'react';
import { history } from './helpers/history';
import { useAuthenticationContext } from './contexts/AuthenticationContext';
import { ValidateAuthentication } from './components/ValidateAuthentication';
import {
  Route,
  Outlet,
  Routes,
  Navigate,
  BrowserRouter,
} from 'react-router-dom';

const lazyLoad = (url) => lazy(() => import(`${url}`));

const Jobs = lazyLoad('./pages/Jobs');
const Order = lazyLoad('./pages/Order');
const Login = lazyLoad('./pages/Login');
const Orders = lazyLoad('./pages/Orders');
const NotFound = lazyLoad('./pages/NotFound');

const Dashboard = lazyLoad('./modules/Dashboard');
const CmsWrapper = lazyLoad('./modules/CmsWrapper');
const JobsWrapper = lazyLoad('./modules/JobsWrapper');
const OrdersWrapper = lazyLoad('./modules/OrdersWrapper');
const MonitoringWrapper = lazyLoad('./modules/MonitoringWrapper');

const General = lazyLoad('./modules/Tabs/Cms/General');
const Upsells = lazyLoad('./modules/Tabs/Cms/Upsells');
const Shipping = lazyLoad('./modules/Tabs/Cms/Shipping');
const Materials = lazyLoad('./modules/Tabs/Cms/Materials');
const EmailPopup = lazyLoad('./modules/Tabs/Cms/EmailPopup');
const Subscriptions = lazyLoad('./modules/Tabs/Cms/Subscriptions');
const Discounts = lazyLoad('./modules/Tabs/Cms/Discounts');

const Pdf = lazyLoad('./modules/Tabs/Monitoring/Pdf');
const Admin = lazyLoad('./modules/Tabs/Monitoring/Admin');
const Upload = lazyLoad('./modules/Tabs/Monitoring/Upload');
const Application = lazyLoad('./modules/Tabs/Monitoring/Application');

const PrivateRoute = () => {
  const { loggedIn } = useAuthenticationContext();
  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: history.location }} />;
  }
  return <Outlet />;
};

export const Router = () => (
  <BrowserRouter history={history}>
    <ValidateAuthentication />
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route element={<Dashboard />}>
          <Route index element={<Navigate to="orders" replace />} />
          <Route path="orders">
            <Route index element={<Navigate to="active" replace />} />
            <Route element={<OrdersWrapper />}>
              <Route path="all" element={<Orders />} />
              <Route path="active" element={<Orders />} />
              <Route path="new" element={<Orders />} />
              <Route path="on-hold" element={<Orders />} />
              <Route path="missing-address" element={<Orders />} />
              <Route path="canceled" element={<Orders />} />
              <Route path="sent" element={<Orders />} />
              <Route path="batch" element={<Orders />} />
            </Route>
            <Route path=":id/details" element={<Order />} />
          </Route>
          <Route path="cms" element={<CmsWrapper />}>
            <Route index element={<Navigate to="general" replace />} />
            <Route path="shipping" element={<Shipping />} />
            <Route path="upsells" element={<Upsells />} />
            <Route path="general" element={<General />} />
            <Route path="materials" element={<Materials />} />
            <Route path="email-popup" element={<EmailPopup />} />
            <Route path="subscriptions" element={<Subscriptions />} />
            <Route path="discounts" element={<Discounts />} />
          </Route>
          <Route path="jobs" element={<JobsWrapper />}>
            <Route index element={<Jobs />} />
          </Route>
          <Route path="monitoring" element={<MonitoringWrapper />}>
            <Route index element={<Navigate to="admin" replace />} />
            <Route path="application" element={<Application />} />
            <Route path="pdf" element={<Pdf />} />
            <Route path="admin" element={<Admin />} />
            <Route path="upload" element={<Upload />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);
